import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Register1() {
	const [err, setErr] = useState(null);
	const login = localStorage.getItem("user");
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	useEffect(() => {
		if (login) {
			navigate("/");
		}
	}, []);
	const onSubmit = (data) => {
		const regex = /^[a-zA-Z0-9]+$/;
		if (data.username.length < 6) {
			setError("username", {
				type: "minLength",
				message: "Số điện thoại tối thiểu 6 kí tự",
			});
			return;
		}
		if (!regex.test(data.username)) {
			setError("username", {
				type: "minLength",
				message: "Số điện thoại không chứa khoảng trắng và ký tự đặc biệt",
			});
			return;
		}
		if (data.password.length < 6) {
			setError("password", {
				type: "minLength",
				message: "Mật khẩu tối thiểu 6 kí tự",
			});
			return;
		}
		if (data.password !== data.ippassword) {
			setError("ippassword", {
				type: "minLength",
				message: "Nhập lại mật khẩu không chính xác",
			});
			return;
		}
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
				username: data.username.toLowerCase(),
				password: data.password,
				ippassword: data.ippassword,
			})
			.then((res) => {
				swal({
					title: "Thông báo",
					text: "Đăng ký thành công",
					icon: "success",
					buttons: "OK",
				}).then(() => {
					axios
						.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
						.then((res) => {
							localStorage.setItem("user", res.data.data);
							navigate("/");
						});
				});
			})
			.catch((err) => {
				setErr(
					err.response.data.message ? err.response.data.message : err.message
				);
			});
	};
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const [showPassword0, setShowPassword0] = useState(false);
	const toggleShowPassword0 = () => {
		setShowPassword0(!showPassword0);
	};
	return (
        <>
            <div className="header">ACCOUNTING</div>
            <div className="logo-login">
                <img src={require("../../img/logo.png")} />
            </div>
            <div className="app123">
                <div className="bg"></div>

                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <h1>Đăng ký</h1>
                    <div className="inputs">
                        <div>
                            <div className="flag">
                                <img src={require("../../img/flag.png")} />
                                +84
                            </div>
                            <input
                                type="tel"
                                {...register("username", { required: true })}
                                className="ip-lg"
                                placeholder="Nhập số điện thoại của bạn"
                                style={{ paddingLeft: "90px" }}
                            />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div>
                            <input type={showPassword0 ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Nhập mật Khẩu của bạn" />
                            <div onClick={toggleShowPassword0} className="change-visible">
                                {showPassword0 ? <VisibilityOff sx={{ color: "#eee" }} /> : <Visibility sx={{ color: "#eee" }} />}
                            </div>
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                        <div>
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("ippassword", { required: true })} placeholder="Nhập lại mật khẩu" />
                            <div onClick={toggleShowPassword} className="change-visible">
                                {showPassword ? <VisibilityOff sx={{ color: "#eee" }} /> : <Visibility sx={{ color: "#eee" }} />}
                            </div>
                            {errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p style={{ color: "red" }}>{err}</p> : null}
                    <p className="p-lg">
                        <Link className="a-lg" to="/login">
                            Đã có tài khoản ? Đăng nhập ngay
                        </Link>
                    </p>
                    <button type="submit" className="btn-lg">
                        Đăng ký
                    </button>
                </form>
            </div>
        </>
    );
}
export default Register1;
